<template>
  <!--富文本编辑器-->
  <quill-editor
      v-model="newContent"
      ref="myQuillEditor"
      :options="editorOption"
  >
  </quill-editor>
</template>

<script>

// 这里是自定义的上传服务器地址，换成自己的

//富文本编辑图片上传配置
const uploadConfig = {
  action: '/api/attachment/upload',  // 必填参数 图片上传地址
  methods: 'POST',  // 必填参数 图片上传方式
  token: localStorage.token,  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
  name: 'img',  // 必填参数 文件的参数名
  size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
  accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'  // 可选 可上传的图片格式
};
// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
  [{'color': []}, {'background': []}],
  ['bold', 'italic', 'underline', 'strike'],
  [{'size': ['small', false, 'large']}],
  ['bold', 'italic'],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],
  [{'indent': '-1'}, {'indent': '+1'}],
  [{'direction': 'rtl'}],
  [{'header': 1}, {'header': 2}],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  ['link', 'image'],
  ['blockquote', 'code-block'],
  [{'font': []}],
  [{'align': []}],
];

import resizeImage from 'quill-image-resize-module' // 图片缩放组件引用
import {ImageDrop} from 'quill-image-drop-module'; // 图片拖动组件引用
Quill.register('modules/imageDrop', ImageDrop); // 注册
Quill.register('modules/resizeImage ', resizeImage) // 注册
import {quillEditor} from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";


export default {
  name: "QuillEditorForm",
  components: {
    quillEditor
  },

  props: ["content"],
  data() {
    return {
      newContent: this.content, // 富文本内容
      // 富文本编辑器配置
      editorOption: {
        placeholder: '请输入',
        theme: "snow", // or 'bubble'
        modules: {
          clipboard: {
            // 粘贴版，处理粘贴时候的自带样式
          },
          toolbar: {
            container: toolOptions, // 工具栏
            handlers: {
              image: function image() {
                var self = this;
                var fileInput = this.container.querySelector('input.ql-image[type=file]');
                if (fileInput === null) {
                  fileInput = document.createElement('input');
                  fileInput.setAttribute('type', 'file');
                  // 设置图片参数名
                  if (uploadConfig.name) {
                    fileInput.setAttribute('name', uploadConfig.name);
                  }
                  // 可设置上传图片的格式
                  fileInput.setAttribute('accept', uploadConfig.accept);
                  fileInput.classList.add('ql-image');
                  // 监听选择文件
                  fileInput.addEventListener('change', function () {
                    // 创建formData
                    var formData = new FormData();
                    formData.append(uploadConfig.name, fileInput.files[0]);
                    formData.append('object', 'product');
                    // 如果需要token且存在token
                    if (uploadConfig.token) {
                      formData.append('token', uploadConfig.token)
                    }
                    // 图片上传
                    var xhr = new XMLHttpRequest();
                    xhr.open(uploadConfig.methods, uploadConfig.action, true);
                    xhr.setRequestHeader('Token', uploadConfig.token);
                    // 上传数据成功，会触发
                    xhr.onload = function (e) {
                      if (xhr.status === 200) {
                        var res = JSON.parse(xhr.responseText);
                        let length = self.quill.getSelection(true).index;
                        var src = process.env.VUE_APP_OSS_URL + '/' + res.data.path
                        //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。
                        self.quill.insertEmbed(length, 'image', src);
                        self.quill.setSelection(length + 1)
                      }
                      fileInput.value = ''
                    };
                    // 开始上传数据
                    xhr.upload.onloadstart = function (e) {
                      fileInput.value = ''
                    };
                    // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
                    xhr.upload.onerror = function (e) {
                    };
                    // 上传数据完成（成功或者失败）时会触发
                    xhr.upload.onloadend = function (e) {
                      // console.log('上传结束')
                    };
                    xhr.send(formData)
                  });
                  this.container.appendChild(fileInput);
                }
                fileInput.click();
              }
            }
          },
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"]
          }
        }
      }
    };
  },

  mounted() {
    //  自定义粘贴图片功能
    let quill = this.$refs.myQuillEditor.quill;
    quill.root.addEventListener("paste", evt => {
          if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
            evt.preventDefault();
            [].forEach.call(evt.clipboardData.files, file => {
              var formData = new FormData();
              formData.append(uploadConfig.name, file);
              formData.append('object', 'product');
              // 如果需要token且存在token
              if (uploadConfig.token) {
                formData.append('token', uploadConfig.token)
              }
              // 图片上传
              var xhr = new XMLHttpRequest();
              xhr.open(uploadConfig.methods, uploadConfig.action, true);
              xhr.setRequestHeader('Token', uploadConfig.token);
              // 上传数据成功，会触发
              xhr.onload = function (e) {
                if (xhr.status === 200) {
                  var res = JSON.parse(xhr.responseText);
                  let length = quill.getSelection(true).index;
                  var src = process.env.VUE_APP_OSS_URL + '/' + res.data.path
                  //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。
                  quill.insertEmbed(length, 'image', src);
                  quill.setSelection(length + 1)
                }
              };
              // 开始上传数据
              xhr.upload.onloadstart = function (e) {
                file.value = ''
              };
              // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
              xhr.upload.onerror = function (e) {
              };
              // 上传数据完成（成功或者失败）时会触发
              xhr.upload.onloadend = function (e) {
                // console.log('上传结束')
              };
              xhr.send(formData)
            });
          }
        },
        false
    );

  },
  methods: {
    getSaveInfo() {
      return this.newContent
    },
  },

};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
