<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  class="mr-1"
                  @click="showCreditModal(0,false)"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">申请临时额度</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--单据编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="单据编号"
                    label-for="tmp_cre_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="tmp_cre_no"
                      size="sm"
                      v-model="condition.tmp_cre_no"
                      placeholder="请输入单据编号"
                  />
                </b-form-group>
              </b-col>
              <!--公司名称-->
              <b-col md="3">
                <modal-select
                    label="公司名称"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'])"
                    :params="['company_name','company_id']"
                    :isAllState="true"
                    modalName="终端客户"
                    placeholder="点击选择公司名称"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>
              <!--店铺名称-->
              <b-col md="3">
                <modal-select
                    label="店铺名称"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'])"
                    :params="['store_name','store_id']"
                    modalName="店铺"
                    placeholder="点击选择店铺"
                    v-model="condition.store_name"
                >
                </modal-select>
              </b-col>
              <!--授信状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="授信状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('company_credit_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择授信状态"
                  />
                </b-form-group>
              </b-col>
              <!--申请人-->
              <b-col md="3">
                <modal-select
                    label="申请人"
                    type="input"
                    v-on:change="fromChildren($event,['creator','creator_id'])"
                    :params="['creator','creator_id']"
                    modalName="创建人"
                    placeholder="点击选择申请人"
                    v-model="condition.creator"
                >
                </modal-select>
              </b-col>
              <!--审核人-->
              <b-col md="3">
                <modal-select
                    label="审核人"
                    type="input"
                    v-on:change="fromChildren($event,['checker','checker_id'])"
                    :params="['checker','checker_id']"
                    modalName="创建人"
                    placeholder="点击选择审核人"
                    v-model="condition.checker"
                >
                </modal-select>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!--授额日期-->
        <template #cell(credit_date)="data">
          {{ toDate(data.item.credit_date) }}
        </template>

        <!--授额有效期-->
        <template #cell(credit_valid_date)="data">
          {{ isEmpty(data.item.credit_valid_date) ? "" : toDate(data.item.credit_valid_date) }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${getCodeColor('company_credit_status', data.item.status)}`">
            {{ getCodeLabel('company_credit_status', data.item.status) }}
          </b-badge>
        </template>

        <!--申请人-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--申请时间-->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--备注-->
        <template #cell(memo)="data">
          <div :id="`memo-${data.item.id}`">
            <span v-if="data.item.memo">
              {{ data.item.memo.substring(0, 6) }}
              <span v-if="data.item.memo.length>6">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo-${data.item.id}`" placement="top">
            {{ data.item.memo }}
          </b-tooltip>
        </template>


        <!--审核人-->
        <template #cell(checker)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--审核时间-->
        <template #cell(check_time)="data">
          {{ toTime(data.item.check_time) }}
        </template>

        <!--驳回原因-->
        <template #cell(reason)="data">
          <div v-if="data.item.reason">
            <feather-icon icon="EyeIcon" @click="showRejectModal(data.item,true)"/>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showCreditModal(data.item.id,false)"
                             v-if="data.item.status === 0 || data.item.status === 3">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="audit(data.item.id,1,'确定提交审核吗？')"
                             v-if="data.item.status === 0 || data.item.status === 3">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showCreditModal(data.item.id,true)"
                             v-if="data.item.status === 1 && [14,29,31].includes(user.role_id)">
              <feather-icon icon="CheckIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showRejectModal(data.item,false)"
                             v-if="data.item.status === 1 && [14,29,31].includes(user.role_id)">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">驳回审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="delById(data.item.id)" v-if="data.item.status === 0 || data.item.status === 3">
              <feather-icon icon="Trash2Icon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!--申请临时额度模态框-->
    <b-modal
        id="creditModal"
        centered
        size="lg"
        title="临额申请"
        hide-footer
        ref="creditModal"
    >
      <company-credit-temp-edit :tmp_id="tmp_id" :audit_flag="audit_flag" ref="creditRef"></company-credit-temp-edit>

      <!--保存、取消、提交按钮-->
      <div class="d-flex align-items-center justify-content-end">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-1"
            @click="save"
        >
          <span class="align-right">保存</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="mr-1"
            @click="cancel"
        >
          <span class="align-right">取消</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            @click="save(1)"
            v-if="!audit_flag"
        >
          <span class="align-right">提交</span>
        </b-button>
      </div>
    </b-modal>
    <!--驳回模态框-->
    <b-modal
        id="rejectModal"
        ok-title="确认"
        @ok="reject(tmp_id,$refs.xyQuillEditorRef.getSaveInfo())"
        ok-only
        centered
        size="xl"
        title="驳回原因"
    >
      <!--驳回原因-->
      <XyQuillEditor
          :content="reason"
          ref="xyQuillEditorRef"
      />

    </b-modal>
    <!--防止重复点击-->
    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        ref="loadingModal"
    >
      <p class="my-4">正在处理，请勿关闭浏览器!</p>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  second
} from '@core/utils/filter'
import companycredittempUseList from './companycredittempUseList'
import companycredittempStore from './companycredittempStore'
import ModalSelect from "@/views/components/modal/ModalSelect";
import Ripple from "vue-ripple-directive";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";
import CompanyCreditTempEdit from "@/views/apps/companycredittemp/CompanyCreditTempEdit";
import {getUserData} from "@/auth/utils";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import XyQuillEditor from "@/views/components/xy/XyQuillEditor";

export default {
  components: {
    CompanyCreditTempEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
    BCardCode,
    XyQuillEditor,
  },
  data() {
    return {
      isShowCard: false,
      companytempcredit: {},
      tmp_id: 0,
      reason: '',
      audit_flag: false,
      user: {},
      rejectReadOnly: false,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('companycredittemp')) store.registerModule('companycredittemp', companycredittempStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companycredittemp')) store.unregisterModule('companycredittemp')
    })

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const showCreditModal = function (id, auditFlag) {
      this.tmp_id = id
      this.audit_flag = auditFlag
      this.$bvModal.show('creditModal')
    }

    const save = function (directAuditFlag) {
      let saveInfo = this.$refs.creditRef.getSaveInfo();
      let flag = true
      if (isEmpty(saveInfo.company_name)) {
        toast.error("请选择 公司")
        flag = false
      }
      if (isEmpty(saveInfo.store_name)) {
        toast.error("请选择 店铺")
        flag = false
      }
      if (isEmpty(saveInfo.credit_date)) {
        toast.error("请选择 授额日期")
        flag = false
      }
      if (isEmpty(saveInfo.limit_amount)) {
        toast.error("请填写 临时额度")
        flag = false
      }
      if (saveInfo.limit_amount < 0) {
        toast.error("临时额度 不能为负")
        flag = false
      }
      if (!isEmpty(saveInfo.credit_date) && !isEmpty(saveInfo.credit_valid_date)) {
        if (second(saveInfo.credit_valid_date) < second(saveInfo.credit_date)) {
          toast.error("授额有效期不能小于授额日期")
          flag = false
        }
      }
      if (!flag) return
      this.$refs['creditModal'].hide()
      //防止重复点击
      this.$refs['loadingModal'].show()
      saveInfo.credit_date = second(saveInfo.credit_date)
      saveInfo.credit_valid_date = second(saveInfo.credit_valid_date)
      saveInfo.directAuditFlag = directAuditFlag === 1 ? 1 : 0
      if (this.audit_flag) {
        //审核通过
        saveInfo.status = 2
        axios.post('api/companycredittemp/audit', saveInfo).then(res => {
          if (res.data.code == 0) {
            toast.success("已提交")
            //重新加载表格
            refetchData()
          } else {
            toast.error(res.data.data)
          }
          this.$refs['loadingModal'].hide()
        })
      } else {
        store.dispatch('companycredittemp/save', saveInfo).then(res => {
          if (res.data.code == 0) {
            toast.success("申请成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
          this.$refs['loadingModal'].hide()
        })
      }
    }

    const audit = function (id, status, title) {
      axios.post('api/companycredittemp/audit', {tmp_cre_id: id, status: status}).then(res => {
        if (res.data.code == 0) {
          toast.success("已提交")
          //重新加载表格
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })

    }

    const delById = function (id) {
      this.$swal({
        title: "确定删除吗",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          axios.post('api/companycredittemp/state', {id: id, state: 0}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const showRejectModal = function (item, flag) {
      this.tmp_id = item.id
      this.reason = item.reason
      this.rejectReadOnly = flag
      this.$bvModal.show('rejectModal')
    }

    const reject = function (id, reason) {
      if (!this.rejectReadOnly) {
        axios.post('api/companycredittemp/audit', {tmp_cre_id: id, status: 3, reason: reason}).then(res => {
          if (res.data.code == 0) {
            //弹窗
            this.$swal({
              icon: 'success',
              title: '已提交!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            //重新加载表格
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const cancel = function () {
      this.$refs['creditModal'].hide()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = companycredittempUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      showCreditModal,
      save,
      audit,
      delById,
      showRejectModal,
      reject,
      fromChildren,
      cancel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
